import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TabLayout from './TabLayout.js'
import Sitanshu from '../Sitanshu.JPG'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    gridContainer: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    img: {
        //transform: 'rotate(90deg)',
        height: 320,
        paddingLeft: '4%',
        paddingTop: '28%',
        [theme.breakpoints.down('md')]: {
            height: 120,
            paddingBottom: '8%'
        },
    },
    tab: {
        paddingTop: '18%',
        paddingLeft :'6%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '4%',
            paddingLeft: '0'
        },
    },
});

function GridLayout(props) {
  const { classes } = props;

  return (<div className={classes.root}>
      <Grid container className={classes.gridContainer} spacing={40}>
        <Grid item xs={6} md={3}>
          <img src={Sitanshu}  className={classes.img} />
        </Grid>
        <Grid item xs={8} md={6}>
          <TabLayout className={classes.tab}/>
        </Grid>
      </Grid>
    </div>
  );
}

GridLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridLayout);
