import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'left',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    button: {
        margin: theme.spacing.unit,
  },
});

function MyProjects(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <ExpansionPanel >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>BearMaps</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            The literal google map of Berkeley, California with functionalities such as pinpointing named locations ,
            routing from point A to point B with real time analysis.
          </Typography>

      <Button href="https://www.github.com/sitanshu10/BearMaps" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Enigma</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            You may have heard of the Enigma machines that Germany used during World War II to encrypt its military
            communications.
            This project involved building a simulator for a very simplified version of this machine (there were
            actually a number of varieties of the machine, each implementing its own encryption algorithm
          </Typography>

      <Button href="https://www.github.com/sitanshu10/Enigma" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Scheme Interpreter</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
          Scheme Interpreter is a project written in python which evaluates a scheme expression . Scheme is a
Programming language that supports multiple paradigms . It is one of the two main dialects of Lisp.
This is an interpreter for a subset of the scheme language using a read_eval_print loop to evaluate scheme expressions. It  parses  the user input into tokens by   performing  Lexical Analysis and Syntactic Analysis . Then it evaluates the expression and prints the result.
          </Typography>

      <Button href="https://www.github.com/sitanshu10/Scheme-Interpreter" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Hand Movement Recognition Glove</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            A device made with integration of software and hardware using flex sensors and Arduino. This was used for hand movement
            recognition using accelorometers. The readings were then used to translate sign language to text, hence
            used for helping vocally challenged people
          </Typography>

      <Button href="https://github.com/sitanshu10/hand-recognition-glove" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>TourGuru</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            A website which helps suggests historical places to visit whilst in a city. The optimum results given by the website take into consideration the users budget and interests.
          </Typography>

      <Button href="https://www.github.com/sitanshu10/TourGuru" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>YelpMaps</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            Created a visualization of restaurant ratings using machine learning and the Yelp academic dataset.
            In this visualization, Berkeley is segmented into regions, where each region is shaded by the predicted rating
            of the closest restaurant (yellow is 5 stars, blue is 1 star).
          </Typography>

      <Button href="https://github.com/sitanshu10/Yelp-Maps" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Game Of Hog</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
          Developed a simulator and multiple strategies for the dice game Hog.
          In Hog, two players alternate turns trying to be the first to end a turn with at least 100 total points. On each turn, the current player chooses some number of dice to roll, up to 10.
           That players score for the turn is the sum of the dice outcomes.
          </Typography>

      <Button href="https://github.com/sitanshu10/Game-Of-Hog" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>AntsVsBees</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography paragraph>
            A game where you create a tower of defence with Ants.
            As the ant queen, you populate your colony with the bravest ants you can muster.
            Your ants must protect their queen from the evil bees that invade your territory.
          </Typography>

      <Button href="https://github.com/sitanshu10/AntsVsBees" className={classes.button}>
        Link
      </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
    </div>
  );
}

MyProjects.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MyProjects);
