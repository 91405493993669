import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

function NavBar(props) {
  const { classes } = props;

  return (
      <div className={classes.root}>
        <Typography variant='h6' gutterBottom>
          Sitanshu Lamba
        </Typography>
      </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavBar);
