import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'left',
    },
    img: {
        //transform: 'rotate(90deg)',
        height: 320,
        paddingLeft: '16%',
        [theme.breakpoints.down('md')]: {
            height: 120,
        },
        paddingTop: '28%',
    },
});

function AboutMe(props) {
    const { classes } = props;

    return (<div className={classes.root}>

            <Typography paragraph>I am a fourth-year Computer Engineering student at Thadomal Shahani Engineering College affiliated with Mumbai University, India.</Typography>
            <Typography paragraph> Since I was introduced to programming in grade 8 , I have been intrigued by the fact that programming is a method of communicating with a machine. I pursued my interest in the field by making it my career. </Typography>
            <Typography paragraph>During my time as an undergraduate at the same institution, I was a part of various activities involving education and social work. I was a volunteer for the Rotoract club of our college(RC-TSEC) for the year 2017-18.</Typography>
            <Typography paragraph>I take part in social events that help boost my knowledge in my areas of interest. For example, I have attended two technology forums of Microsoft and Dell Technologies. </Typography>

            <Typography paragraph>Contact me:</Typography>
            <Typography paragraph>info@sitanshulamba.com</Typography>
            <Button href="https://www.linkedin.com/in/sitanshulamba/" variant="contained" size="large" color="primary" >LinkedIn</Button>
    </div>
    );
}

AboutMe.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutMe);
